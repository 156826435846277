$(document).ready(function () {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function () {
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function (e) {
        var target = $(this).attr("target"),
            url = $(this).attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    }).click(function () {
        var target = $(this).find("a.js-click-item-link").attr("target"),
            url = $(this).find("a.js-click-item-link").attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    });




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 80; // Distance from Browserbottom & -top where the animation should start

    function fadeInElements() {
        var viewPortStart = $(window).scrollTop(),
            viewPortEnd = viewPortStart + $(window).height();

        $(".animateIn:visible").each(function () {
            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight();

            if ((elementTop + offset) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }
            // else {
            //     $(this).removeClass("animateIn--active");
            // }
        });



        $(".js-countup").each(function () {
            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight();

            if ((elementTop) <= viewPortEnd && (elementBottom) >= viewPortStart) {

                // check for countup - if in viepoint fire countUp (once)
                if (!$(this).hasClass("counted")) {
                    countup();
                    $(this).addClass("counted")
                }
            }
        });
    }

    $(window).scroll(function () {
        fadeInElements();
    });

    fadeInElements();



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * countup
    // *
    // *
    // triggered when in viewport (animateIn)
    function countup() {
        // count up
        var options = {
            useEasing: true,
            useGrouping: true,
            separator: '.',
            decimal: '.',
        };

        $(".js-countup-number").each(function () {
            // avoid jumping
            // var width = $(this).width();
            // $(this).css("width", width);

            var counterID = $(this).attr("id"),
                countTo = $(this).data("count"),
                count = new CountUp(counterID, 0, countTo, 0, 4, options);
            count.start();
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function () {
        if (location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        } else {
            $(this).attr('target', '_blank');
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * navButton
    // *
    // *
    $(".js-nav-button").click(function () {
        $("body").toggleClass("freeze");
        $(".js-nav-main").toggleClass("active");
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * sliderHero
    // *
    // *
    var $sliderHero = $(".js-sliderhero");

    if ($sliderHero.length) {
        $sliderHero.each(function () {
            $(this).slick({
                fade: false,
                dots: true,
                arrows: false,
                autoplay: true,
                waitForAnimate: false,
                appendDots: $(this).parents(".js-sliderhero-wrapper").find(".js-sliderhero-dots"),
                infinite: true,
                autoplaySpeed: 5000
            });
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * sliderQuote
    // *
    // *
    var $sliderQuote = $(".js-sliderquote");

    if ($sliderQuote.length) {
        $sliderQuote.each(function () {
            $(this).slick({
                fade: true,
                dots: false,
                arrows: true,
                autoplay: false,
                waitForAnimate: false,
                infinite: true,
                prevArrow: $(this).siblings(".js-sliderquote-nav").find(".js-sliderquote-prev"),
                nextArrow: $(this).siblings(".js-sliderquote-nav").find(".js-sliderquote-next"),
            });
        });
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * smooth scrolling and anchor links
    // *
    // *

    ScrollSmoother.create({
        smoothTouch: 0.1,        // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
    });

    ScrollTrigger.normalizeScroll(true);

    // check if hash and module - scroll to module
    var hash = window.location.hash.split('#')[1],
        $anchor = $('#' + hash);

    if (hash && $anchor) {
        gsap.to(window, {
            duration: 1,
            scrollTo: "#" + hash
        });
    }

    // check if link is on another site - if not, scroll to module
    $(".button[href]").click(function (event) {
        var beforeHash = $(this).attr("href").split("#")[0];
        var thisHash = $(this).attr("href").split("#")[1];

        if (!beforeHash) {
            console.log("trigger");

            event.preventDefault();

            gsap.to(window, {
                duration: 1,
                scrollTo: "#" + thisHash
            });
        }
    })

    $(".js-scrolldown").click(function() {
        gsap.to(window, {
            duration: 1,
            scrollTo: {
                y: "#" + $(this).data("href"),
                offsetY: 200
            }
        });
    })

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * carouselTeaser
    // *
    // *
    // page scroll triggers carousel swipes and slide number

    var $carouselTeaser = $(".js-carouselteaser");

    $carouselTeaser.each(function () {

        var $carouselItem = $(this).find(".js-carouselteaser-item"),
            $carouselWrapper = $(this).parents(".js-carouselteaser-wrapper"),
            carouselLength = $carouselItem.length,
            headerHeight = $(".js-header").height() + 30,
            startOffset = window.innerWidth < 800 ? 10 : 0,
            animationPriority = 1000 - $(this).parents(".js-gsap-wrapper").data("index");

        gsap.to($carouselItem, {
            xPercent: -100 * (carouselLength - 1),
            ease: "none",
            scrollTrigger: {
                trigger: $carouselWrapper,
                scrub: true,
                pin: true,
                snap: 1 / (carouselLength - 1),
                markers: false,
                start: "top+=" + startOffset + "% top+=" + headerHeight,
                end: "bottom+=100% top+=" + headerHeight,
                refreshPriority: animationPriority,
                // Update the "number" element with the current progress
                onUpdate: function (self) {
                    var carouselProgress = Math.floor(carouselLength * self.progress) + 1;
                    if (carouselProgress > carouselLength) {
                        return;
                    } else {
                        $carouselWrapper.find(".js-carouselteaser-number").html(carouselProgress);
                    }
                }
            }
        });
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * sliderGallery
    // *
    // *
    // page scroll triggers sliderGallery horizontal scroll

    var $sliderGallery = $(".js-slidergallery");

    $sliderGallery.each(function () {
        var $sliderItems = $(this).find(".js-slidergallery-item"),
            sliderLength = $sliderItems.length,
            windowWidth = $(window).width(),
            headerHeight = $(".js-header").height() + 30,
            animationPriority = 1000 - $(this).parents(".js-gsap-wrapper").data("index");

        // console.log((windowWidth / 100) * sliderLength);

        gsap.to($sliderItems, {
            // xPercent: -100 * ((windowWidth / 100) * sliderLength),
            xPercent: -100 * (sliderLength - 4),
            ease: "none",
            scrollTrigger: {
                trigger: $(this),
                scrub: true,
                pin: true,
                markers: false,
                start: "top top+=" + headerHeight,
                end: "bottom+=100% top+=" + headerHeight,
                refreshPriority: animationPriority
            }
        });
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * instagram
    // *
    // *

    var $instagram = $(".js-instagram");

    if ($instagram.length) {
        $instagram.each(function () {
            $(this).slick({
                fade: false,
                dots: false,
                arrows: true,
                autoplay: false,
                waitForAnimate: false,
                infinite: true,
                variableWidth: true,
                prevArrow: $(this).parents(".js-instagram-wrapper").find(".js-instagram-prev"),
                nextArrow: $(this).parents(".js-instagram-wrapper").find(".js-instagram-next"),
            });
        });
    }




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * imageMorph
    // *
    // *
    var $imageMorph = $(".js-imagemorph");

    $imageMorph.each(function () {
        var headerHeight = $(".js-header").height() + 30,
            wrapperHeight = $(this).parent(".imageMorph__figure").height(),
            animationPriority = 1000 - $(this).parents(".js-gsap-wrapper").data("index");

        gsap.fromTo($(this), {
            width: wrapperHeight,
            borderRadius: "1100px"
        },
            {
                width: "100%",
                borderRadius: "0",
                scrollTrigger: {
                    trigger: $(this).parent(".imageMorph__figure"),
                    scrub: true,
                    pin: true,
                    markers: false,
                    start: "top top+=" + headerHeight,
                    end: "bottom+=50% top+=" + headerHeight,
                    refreshPriority: animationPriority
                }
            });
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * cloning
    // *
    // *
    // clone something and put it somewhere else
    $(".js-clone-origin").each(function () {
        $(this).parents(".js-clone-wrapper").find(".js-clone-target").html($(this).html());
    })



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * expand
    // *
    // *
    $(".js-expand").click(function () {
        if ($(this).hasClass("active")) {
            $("html").animate({ scrollTop: $(this).offset().top - 280 }, 400);
        };
        $(this).toggleClass("active").find(".js-expand-content").slideToggle();
    })



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * imagecompare
    // *
    // *
    $(".js-imagecompare").twentytwenty();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * loading lazy gesap fix
    // *
    // *
    const lazyImages = Array.from(document.querySelectorAll("img[loading='lazy']"));
    lazyImages.forEach(function (lazyImage) {
        lazyImage.addEventListener('load', function () {
            ScrollTrigger.refresh();
        });
    });

});



// * * * * * * * * * * * * * * * * * * * * * * * * *
// * sticky Header
// *
// *
$(window).on("load scroll", function () {

    if ($(window).scrollTop() >= 80) {
        $(".js-header").addClass("active");
    } else {
        $(".js-header").removeClass("active");
    }


});

var windowWidth = $(window).width();

$(window).resize(function () {
    if (windowWidth != $(window).width()) {
        location.reload();
        return;
    }
});

